import React from "react"

import Layout from "../components/layout"
import imageComplet from "../images/logo_js_full.png"
import SEO from "../components/seo"
import { AiOutlineMail, AiOutlineWhatsApp, AiFillPhone } from "react-icons/ai"

import { Carousel } from "react-bootstrap"
import Img from "gatsby-image"

const IndexPage = props => (
  <Layout id="home">
    <SEO title="Home" />

    <h3>
      Instalação e manutenção de ar-condicionados em Joinville-SC e região
    </h3>
    <div id="destaques" class="linha_div">
      <div
        class="coluna_div_100"
        style={{ maxWidth: `100%`, maxHeight: `70%`, marginBottom: `1.45rem` }}
      >
        <Carousel interval="2000">
          <Carousel.Item>
            <Img
              fluid={props.data.slide1ql.childImageSharp.fluid}
              alt="This is the first image"
            />
          </Carousel.Item>
          <Carousel.Item>
            <Img
              fluid={props.data.slide2ql.childImageSharp.fluid}
              alt="This is the second image"
            />
          </Carousel.Item>
          <Carousel.Item>
            <Img
              fluid={props.data.slide3ql.childImageSharp.fluid}
              alt="This is the three image"
            />
          </Carousel.Item>
          <Carousel.Item>
            <Img
              fluid={props.data.slide4ql.childImageSharp.fluid}
              alt="This is the four image"
            />
          </Carousel.Item>
        </Carousel>
      </div>
    </div>
    <div id="contact">
      <div className="footer-top">
        <h3>Contato:</h3>
        <div>
          <a href="tel:+5547999573641">
            <AiFillPhone color="white" size={25} />
          </a>
          <a href="whatsapp://send?phone=5547999573641">
            <AiOutlineWhatsApp color="white" size={25} />
          </a>
          <a href="mailto:js-sales@hotmail.com?subject=Solicitação de orçamento">
            <AiOutlineMail color="white" size={25} />
          </a>
        </div>
      </div>
      <div className="footer">
        <img src={imageComplet} alt="contact" />
      </div>
    </div>
  </Layout>
)

export const query = graphql`
  query {
    slide1ql: file(relativePath: { eq: "slide1.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, maxHeight: 960, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    slide2ql: file(relativePath: { eq: "slide2.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, maxHeight: 960, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    slide3ql: file(relativePath: { eq: "slide3.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, maxHeight: 960, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    slide4ql: file(relativePath: { eq: "slide4.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, maxHeight: 960, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default IndexPage
